@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300&display=swap%27');
body {
  font-family: 'Hind';
  margin: 0;
  cursor: url('./media/768x492_tous-trolls.cur');
}

* {
  text-decoration: none;
  list-style: none;
}
