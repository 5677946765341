@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.acceptedFiles {
  font-size: 0.7em;
  margin-top: 0.1vw;
}
.addDocumentation {
  padding: 10px;
  text-align: center;
  text-shadow: 1px 1px 1px #7a77f2;
}

.documentationGlobal {
  text-align: center;
  padding: 10px;
  border: solid 1 px;
  box-shadow: 0 10px 10px rgb(134 134 134);
  margin: 2%;
  margin-bottom: 50px;
}

.select {
  position: relative;
  top: 5px;
  background-color: white;
  color: #7a77f2;
}

.selectField {
  border: 1px solid #7a77f2;
  height: 40px;
  border-radius: 8px;
  width: 85%;
  display: flex;
  font-size: 16px;
  margin-left: 8%;
}
.textAreaLabel {
  position: relative;
  top: 12px;
  background-color: white;
  color: #7a77f2;
}
.textArea {
  border: 1px solid #7a77f2;
  margin-top: 40px;
  border-radius: 5px;
  width: 85%;
  display: flex;
  margin: 1%;
  font-size: 16px;
  margin-left: 8%;
}

.bouton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7a77f2;
  height: 40px;
  border-radius: 8px;
  width: 30%;
  font-size: 16px;
}
.bouton:hover {
  transform: scale(1.1);
}
.container {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}
