@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body:before {
  position: fixed;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff400;
  clip-path: circle(30% at right 70%);
  z-index: -4;
}

body:after {
  position: fixed;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff400;
  clip-path: circle(20% at 10% 10%);
  z-index: -4;
}

body:after {
  position: fixed;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff400;
  clip-path: circle(20% at 10% 10%);
  z-index: -4;
}

.home {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.prices {
  max-width: 100%;
}

.landing-page-container {
  height: 500px;
  width: 100%;
}

.landing-page {
  position: absolute;
}

.intro {
  background-image: url('../../media/backgroundkine.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 500px;
  z-index: -1;
}
.intro p {
  text-align: center;
  margin: 1%;
  text-shadow: white 3px 0 2px;
  font-size: x-large;
  margin-top: 7%;
  margin-left: 10%;
  margin-right: 10%;
  font-family: 'Roboto', sans-serif;
}
.intro h1 {
  text-shadow: white 3px 0 2px;
  font-size: 40px;
  margin-top: 8%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}
.intro h3 {
  text-shadow: white 3px 0 2px;
  font-size: xx-large;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.tarif-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10%;
}

.signUp {
  display: flex;
  margin: 20px;
  background: #7a77f2;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5%;
  width: 170px;
  justify-content: center;
  color: white;
  font-size: 23px;
}

.signIn {
  display: flex;
  justify-content: center;
  margin: 20px;
  background: #7a77f2;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5%;
  width: 170px;
  color: white;
}

.title-home {
  font-size: 40px;
  letter-spacing: -3px;
  margin-top: 40px;
  margin-bottom: 30px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-10%);
  display: flex;
  justify-content: center;
  text-align: center;
}

hr {
  border: none;
  border: 2px double #333;
  overflow: visible;
  text-align: center;
  width: 90%;
  background-color: #0602fa;
}

.priceText {
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.titre {
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.kine-container {
  margin-bottom: 5%;
}

.bouton-inscription {
  font-family: 'Roboto';
  height: 70px;
  background: #7a77f2;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  width: 260px;
  cursor: pointer;
  justify-content: center;
  margin-top: 10%;
}
.bouton-inscription:hover {
  transform: scale(1.05);
}
.bouton-connexion {
  text-decoration: none;
  border: none;
  background-color: white;
  cursor: pointer;
  margin-top: 20%;
  font-size: 18px;
}
.bouton-connexion:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
}
