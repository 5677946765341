@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.signInForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.title {
  width: 90%;
  text-align: center;
  font-family: 'Roboto';
  font-weight: normal;
}

.container-forgotpassword {
  display: flex;
  justify-content: flex-end;
  margin-right: 8%;
  font-weight: bold;
  font-family: 'Roboto';
  font-size: 14px;
}

.text-check {
  font-size: 18px;
  font-family: 'Roboto';
  color: #0258fa;
  font-weight: bold;
  margin-left: 2%;
}

.container-button-signIn {
  display: flex;
  justify-content: center;
}

.button-signIn {
  font-family: 'Roboto';
  width: 85%;
  height: 54px;
  background: #7a77f2;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  margin-bottom: 10%;
}

.text-newMember {
  font-family: 'Roboto';
  text-align: center;
  font-size: 18px;
}

.inscription {
  font-size: 18px;
  font-family: 'Roboto';
  color: #0258fa;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .background {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .background {
    background-image: url('../../media/backgroundkine.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .container-title {
    margin-top: 25%;
  }
  .card-body-login {
    border: solid 1 px;
    box-shadow: 0 10px 10px rgb(134, 134, 134);
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .card-login {
    position: absolute;
    width: 55vw;
  }

  .container-card {
    display: flex;
    justify-content: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 90%;
    z-index: -1;
  }

  .container-title {
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text-check {
    margin-top: 1.5vw;
  }

  .container-forgotpassword {
    height: 1px;
    margin-bottom: 0.1vw;
  }

  .text-newMember {
    height: 1.5vw;
    margin-bottom: 0.5vw;
  }

  .button-signIn {
    margin-bottom: 3vw;
    cursor: pointer;
  }
  .container-button-signIn {
    height: 5vw;
  }
  .textSignUp {
    height: 1.5vw;
    margin-bottom: 1vw;
    margin-bottom: 3%;
  }
}
