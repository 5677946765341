@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.signUpForm {
  display: flex;
  flex-direction: column;
}

.field {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1%;
}

.login-btn {
  background-color: #1ebe73;
  color: white;
  border-color: transparent;
  border-radius: 20px;
  align-items: center;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.acceptedFiles {
  display: flex;
  justify-content: center;
  margin-bottom: 4vw;
  text-align: center;
}

.login-btn:hover {
  background-color: white;
  color: #1ebe73;
}

.card-body-signup {
  border: solid 1 px;
  box-shadow: 0 10px 10px rgb(134 134 134);
  margin: 2%;
}
.card-signup {
  margin-bottom: 70px;
}

input#lastname.form-control,
input#firstname.form-control,
input#birthdate.form-control,
input#email.form-control,
input#confirmeEmail.form-control,
input#password.form-control,
input#confirmPassword.form-control,
select.form-control,
input#address.form-control,
input#phone.form-control,
input#siteWeb.form-control,
input#siret.siret.form-control {
  height: 40px;
  border: 1px solid #7a77f2;
  box-sizing: border-box;
  border-radius: 8px;
  width: 85%;
  display: flex;
  margin: 1%;
  font-size: 16px;
  margin-left: 8%;
}

.container-form {
  display: flex;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
}

.container-card-form {
  width: 100%;
}

.button-signup {
  height: 54px;
  background: #7a77f2;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 8px;
  width: 65%;
  margin: 5%;
  color: white;
  font-size: 18px;
}
.button-signup:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.container-button {
  display: flex;
  justify-content: center;
}
.container-button p {
  display: flex;
  justify-content: center;
}

.container-avatar {
  display: flex;
  justify-content: center;
  margin: 8%;
  margin-bottom: 1px;
}

.container-choose-radio {
  display: flex;
  flex-direction: column;
  margin: 2%;
}
.container-radio-kine {
  font-size: 20px;
}
.container-radio-entreprise {
  font-size: 20px;
}
#kineCheck,
#companyCheck,
input.form-check-input {
  width: 45px;
  height: 20px;
}

.alertError {
  color: red;
  margin: 2%;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
}
.check-validation {
  font-size: 15px;
  margin: 4%;
}
.form-check {
  display: flex;
  align-items: center;
}
hr {
  border: 1px solid #7a77f2;
  width: 60%;
}
.connecter {
  display: flex;
  justify-content: center;
  color: #7a77f2;
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 5%;
}
.text-alreadyAccount {
  display: flex;
  justify-content: center;
  font-size: 18px;
}

@media (max-width: 680px) {
  .container-form {
    display: flex;
    align-items: center;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 10%;
  }
}
