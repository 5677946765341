@import url(https://fonts.googleapis.com/css2?family=Hind:wght@300&display=swap%27);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  font-family: 'Hind';
  margin: 0;
  cursor: url(/static/media/768x492_tous-trolls.b57ba620.cur);
}

* {
  text-decoration: none;
  list-style: none;
}

body:before {
  position: fixed;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff400;
  -webkit-clip-path: circle(30% at right 70%);
          clip-path: circle(30% at right 70%);
  z-index: -4;
}

body:after {
  position: fixed;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff400;
  -webkit-clip-path: circle(20% at 10% 10%);
          clip-path: circle(20% at 10% 10%);
  z-index: -4;
}

body:after {
  position: fixed;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff400;
  -webkit-clip-path: circle(20% at 10% 10%);
          clip-path: circle(20% at 10% 10%);
  z-index: -4;
}

.home {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.prices {
  max-width: 100%;
}

.landing-page-container {
  height: 500px;
  width: 100%;
}

.landing-page {
  position: absolute;
}

.intro {
  background-image: url(/static/media/backgroundkine.49227c01.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 500px;
  z-index: -1;
}
.intro p {
  text-align: center;
  margin: 1%;
  text-shadow: white 3px 0 2px;
  font-size: x-large;
  margin-top: 7%;
  margin-left: 10%;
  margin-right: 10%;
  font-family: 'Roboto', sans-serif;
}
.intro h1 {
  text-shadow: white 3px 0 2px;
  font-size: 40px;
  margin-top: 8%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}
.intro h3 {
  text-shadow: white 3px 0 2px;
  font-size: xx-large;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.tarif-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10%;
}

.signUp {
  display: flex;
  margin: 20px;
  background: #7a77f2;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5%;
  width: 170px;
  justify-content: center;
  color: white;
  font-size: 23px;
}

.signIn {
  display: flex;
  justify-content: center;
  margin: 20px;
  background: #7a77f2;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5%;
  width: 170px;
  color: white;
}

.title-home {
  font-size: 40px;
  letter-spacing: -3px;
  margin-top: 40px;
  margin-bottom: 30px;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-10%);
          transform: translateX(-50%) translateY(-10%);
  display: flex;
  justify-content: center;
  text-align: center;
}

hr {
  border: none;
  border: 2px double #333;
  overflow: visible;
  text-align: center;
  width: 90%;
  background-color: #0602fa;
}

.priceText {
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.titre {
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.kine-container {
  margin-bottom: 5%;
}

.bouton-inscription {
  font-family: 'Roboto';
  height: 70px;
  background: #7a77f2;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  width: 260px;
  cursor: pointer;
  justify-content: center;
  margin-top: 10%;
}
.bouton-inscription:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.bouton-connexion {
  text-decoration: none;
  border: none;
  background-color: white;
  cursor: pointer;
  margin-top: 20%;
  font-size: 18px;
}
.bouton-connexion:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media screen and (max-width: 768px) {
}

.footer {
  line-height: 25px;
  font-weight: bold;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr, 1fr;
  background-color: #ffe400;
}

.networkLink {
  grid-column: 1;
  grid-row: 1;
  margin-left: 2.5vw;
}

.titleKine {
  font-size: 1.8em;
  height: 1vw;
  margin-left: 0.5vw;
}

.networkLogo {
  padding-left: 0.4vw;
}

.credits {
  grid-column: 2;
  grid-row: 1;
  margin-top: 4vw;
}

.adress {
  margin-left: 2.5vw;
  margin-top: 1vw;
}

.conditions {
  list-style: none;
  grid-column: 1/3;
  grid-row: 2;
  text-align: center;
  line-height: 20px;
}

.disc {
  visibility: hidden;
}
@media screen and (min-width: 768px) {
  .networkLink {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 3fr;
    grid-template-rows: 1;
    margin-left: 4vw;
  }

  .titleKine {
    margin-right: 10vw;
  }

  .networkLogo {
    margin-top: 1.3vw;
    margin-left: 1.5vw;
  }

  .credits {
    margin-top: 0.5vw;
    text-align: right;
    margin-right: 2vw;
  }

  .adress br {
    display: none;
  }

  .conditions {
    list-style-type: disc;
  }

  .conditionElement {
    text-align: center;
    display: inline;
  }

  .disc {
    visibility: visible;
  }
}

.carousel {
  width: 100%;
  height: 400px;
  margin: 0 auto;
}

.carousel-inner {
  height: 100%;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.carousel-inner .left {
  flex: 5% 1;
  height: 100%;
  display: grid;
  place-items: center;
  color: #010101;
  cursor: pointer;
}

.carousel-inner .center {
  flex: 80% 1;
  height: 100%;
  display: grid;
  place-items: center;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  text-align: center;
}

.carousel-inner .right {
  flex: 5% 1;
  height: 100%;
  display: grid;
  place-items: center;
  color: #010101;
  cursor: pointer;
}

.center h1 {
  font-size: 3rem;
  font-family: 'Payfair Display';
}

p {
  font-size: 1rem;
}

.carousel .carousel-inner h1 {
  background-color: rgb(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 9px;
}

.subtitles {
  background-color: rgb(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 9px;
}

body {
  width: 100%;
  margin: 0;
}

.box-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-content: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  width: 60%;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: #f6f6f6;
  box-shadow: 3px 3px 3px lightgrey;
}

.title {
  text-decoration: none;
  margin-bottom: 2px;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(
      to top,
      rgba(255, 230, 0, 0.952) 0%,
      rgba(255, 230, 0, 0.952) 10%,
      transparent 10.01%
    )
    no-repeat left bottom / 0 100%;
  transition: background-size 0.5s;
}

.title:hover {
  background-size: 100% 100%;
}

.description {
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1px;
  text-align: justify;
}

.price {
  margin-bottom: 20px;
}
.download-file {
  margin-left: auto;
  margin-right: auto;
  background: #7a77f2;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 10px;
  height: 6vw;
  justify-content: center;
  color: white;
  font-size: 23px;
  padding-left: 15%;
  padding-right: 15%;
}
.download-file:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.discover {
  margin-bottom: 5px;
}
.download-file:visited {
  color: white;
}

@media screen and (min-width: 768px) {
  .box-item {
    max-width: 50%;
    flex-wrap: no-wrap;
    margin: 0, auto;
  }

  .discover {
    height: 5vw;
  }
  .download-file {
    border-radius: 10px;
    justify-content: center;
    color: white;
    width: 50%;
    height: 4vw;
    padding-top: 0.4vw;
    padding-bottom: 0%;
  }
}

body {
  width: 100%;
  margin: 0;
}

.box-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-content: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  width: 60%;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: #f6f6f6;
  box-shadow: 3px 3px 3px lightgrey;
}

.title {
  text-decoration: none;
  margin-bottom: 2px;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(
      to top,
      rgba(255, 230, 0, 0.952) 0%,
      rgba(255, 230, 0, 0.952) 10%,
      transparent 10.01%
    )
    no-repeat left bottom / 0 100%;
  transition: background-size 0.5s;
}

.title:hover {
  background-size: 100% 100%;
}

.description {
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1px;
  text-align: justify;
}

.price {
  margin-bottom: 20px;
}

.discover {
  margin-bottom: 5px;
}

.link-website {
  margin-left: auto;
  margin-right: auto;
  background: #7a77f2;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  height: 6vw;
  justify-content: center;
  color: white;
  font-size: 23px;
  padding-left: 5%;
  padding-right: 5%;
}
.link-website:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.link-website:visited {
  color: white;
}

@media screen and (min-width: 768px) {
  .box-item {
    max-width: 50%;
    flex-wrap: no-wrap;
    margin: 0, auto;
  }

  .discover {
    height: 5vw;
  }
  .link-website {
    border-radius: 10px;
    justify-content: center;
    color: white;
    width: 50%;
    height: 4vw;
    padding-top: 0.4vw;
    padding-bottom: 0%;
  }
}

body {
  width: 100%;
  margin: 0;
}

.box-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-content: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  width: 60%;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: #f6f6f6;
  box-shadow: 3px 3px 3px lightgrey;
}

.title {
  text-decoration: none;
  margin-bottom: 2px;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(
      to top,
      rgba(255, 230, 0, 0.952) 0%,
      rgba(255, 230, 0, 0.952) 10%,
      transparent 10.01%
    )
    no-repeat left bottom / 0 100%;
  transition: background-size 0.5s;
}

.title:hover {
  background-size: 100% 100%;
}

.description {
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1px;
  text-align: justify;
}
.date {
  margin-bottom: 0;
}
.price {
  margin-top: 0;
  margin-bottom: 20px;
}

.discover {
  margin-bottom: 5px;
}

.link-website {
  margin-left: auto;
  margin-right: auto;
  background: #7a77f2;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 10px;
  height: 6vw;
  justify-content: center;
  color: white;
  font-size: 23px;
  padding-left: 5%;
  padding-right: 5%;
  cursor: pointer;
}
.link-website:hover {
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.link-website:visited {
  color: white;
}

@media screen and (min-width: 768px) {
  .box-item {
    max-width: 50%;
    flex-wrap: no-wrap;
    margin: 0, auto;
  }

  .discover {
    height: 5vw;
  }
  .link-website {
    border-radius: 10px;
    justify-content: center;
    color: white;
    width: 50%;
    height: 4vw;
    padding-top: 0.4vw;
    padding-bottom: 0%;
  }
}

.search-input {
  height: 35px;
  border-radius: 15px;
  padding: 8px;
  border: 1px solid #7a77f2;
  box-sizing: border-box;
}

@media screen and (min-width: 768px) {
  .search-input {
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: center;
  }
}

.alldoc {
  text-align: center;
  font-size: 40px;
  padding: 10px;
  text-align: center;
  text-shadow: 1px 1px 1px #7a77f2;
}

.search-engine {
  font-family: 'Hind';
  padding-left: 2%;
}
.documentation-results {
  margin-bottom: 10vw;
}

@media screen and (min-width: 768px) {
  .documentation-results {
    margin-bottom: 5vw;
  }
}

.allservices {
  text-align: center;
  font-size: 40px;
  padding: 10px;
  text-align: center;
  text-shadow: 1px 1px 1px #7a77f2;
}
.search-engine {
  font-family: 'Hind';
  padding-left: 2%;
}
.service-results {
  margin-bottom: 10vw;
}

@media screen and (min-width: 768px) {
  .service-results {
    margin-bottom: 5vw;
  }
}

.label {
  position: relative;
  top: 12px;
  background-color: white;
  color: #7a77f2;
}
.input {
  border: 1px solid #7a77f2;
  height: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  width: 85%;
  display: flex;
  margin: 1%;
  font-size: 16px;
  margin-left: 8%;
}

.acceptedFiles {
  font-size: 0.7em;
  margin-top: 0.1vw;
}
.addDocumentation {
  padding: 10px;
  text-align: center;
  text-shadow: 1px 1px 1px #7a77f2;
}

.documentationGlobal {
  text-align: center;
  padding: 10px;
  border: solid 1 px;
  box-shadow: 0 10px 10px rgb(134 134 134);
  margin: 2%;
  margin-bottom: 50px;
}

.select {
  position: relative;
  top: 5px;
  background-color: white;
  color: #7a77f2;
}

.selectField {
  border: 1px solid #7a77f2;
  height: 40px;
  border-radius: 8px;
  width: 85%;
  display: flex;
  font-size: 16px;
  margin-left: 8%;
}
.textAreaLabel {
  position: relative;
  top: 12px;
  background-color: white;
  color: #7a77f2;
}
.textArea {
  border: 1px solid #7a77f2;
  margin-top: 40px;
  border-radius: 5px;
  width: 85%;
  display: flex;
  margin: 1%;
  font-size: 16px;
  margin-left: 8%;
}

.bouton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7a77f2;
  height: 40px;
  border-radius: 8px;
  width: 30%;
  font-size: 16px;
}
.bouton:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.container {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}

.allformations {
  text-align: center;
  font-size: 40px;
  padding: 10px;
  text-align: center;
  text-shadow: 1px 1px 1px #7a77f2;
}
.search-engine {
  font-family: 'Hind';
  padding-left: 2%;
}
.formation-results {
  margin-bottom: 10vw;
}

@media screen and (min-width: 768px) {
  .formation-results {
    margin-bottom: 5vw;
  }
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.kine,
.pasKine,
.supa2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid black;
}

.bouton {
  margin-bottom: 10px;
}

.titre {
  text-align: center;
}

.signInForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.title {
  width: 90%;
  text-align: center;
  font-family: 'Roboto';
  font-weight: normal;
}

.container-forgotpassword {
  display: flex;
  justify-content: flex-end;
  margin-right: 8%;
  font-weight: bold;
  font-family: 'Roboto';
  font-size: 14px;
}

.text-check {
  font-size: 18px;
  font-family: 'Roboto';
  color: #0258fa;
  font-weight: bold;
  margin-left: 2%;
}

.container-button-signIn {
  display: flex;
  justify-content: center;
}

.button-signIn {
  font-family: 'Roboto';
  width: 85%;
  height: 54px;
  background: #7a77f2;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  margin-bottom: 10%;
}

.text-newMember {
  font-family: 'Roboto';
  text-align: center;
  font-size: 18px;
}

.inscription {
  font-size: 18px;
  font-family: 'Roboto';
  color: #0258fa;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .background {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .background {
    background-image: url(/static/media/backgroundkine.49227c01.png);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .container-title {
    margin-top: 25%;
  }
  .card-body-login {
    border: solid 1 px;
    box-shadow: 0 10px 10px rgb(134, 134, 134);
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .card-login {
    position: absolute;
    width: 55vw;
  }

  .container-card {
    display: flex;
    justify-content: center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 90%;
    z-index: -1;
  }

  .container-title {
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text-check {
    margin-top: 1.5vw;
  }

  .container-forgotpassword {
    height: 1px;
    margin-bottom: 0.1vw;
  }

  .text-newMember {
    height: 1.5vw;
    margin-bottom: 0.5vw;
  }

  .button-signIn {
    margin-bottom: 3vw;
    cursor: pointer;
  }
  .container-button-signIn {
    height: 5vw;
  }
  .textSignUp {
    height: 1.5vw;
    margin-bottom: 1vw;
    margin-bottom: 3%;
  }
}

.signUpForm {
  display: flex;
  flex-direction: column;
}

.field {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1%;
}

.login-btn {
  background-color: #1ebe73;
  color: white;
  border-color: transparent;
  border-radius: 20px;
  align-items: center;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.acceptedFiles {
  display: flex;
  justify-content: center;
  margin-bottom: 4vw;
  text-align: center;
}

.login-btn:hover {
  background-color: white;
  color: #1ebe73;
}

.card-body-signup {
  border: solid 1 px;
  box-shadow: 0 10px 10px rgb(134 134 134);
  margin: 2%;
}
.card-signup {
  margin-bottom: 70px;
}

input#lastname.form-control,
input#firstname.form-control,
input#birthdate.form-control,
input#email.form-control,
input#confirmeEmail.form-control,
input#password.form-control,
input#confirmPassword.form-control,
select.form-control,
input#address.form-control,
input#phone.form-control,
input#siteWeb.form-control,
input#siret.siret.form-control {
  height: 40px;
  border: 1px solid #7a77f2;
  box-sizing: border-box;
  border-radius: 8px;
  width: 85%;
  display: flex;
  margin: 1%;
  font-size: 16px;
  margin-left: 8%;
}

.container-form {
  display: flex;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
}

.container-card-form {
  width: 100%;
}

.button-signup {
  height: 54px;
  background: #7a77f2;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 8px;
  width: 65%;
  margin: 5%;
  color: white;
  font-size: 18px;
}
.button-signup:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  cursor: pointer;
}

.container-button {
  display: flex;
  justify-content: center;
}
.container-button p {
  display: flex;
  justify-content: center;
}

.container-avatar {
  display: flex;
  justify-content: center;
  margin: 8%;
  margin-bottom: 1px;
}

.container-choose-radio {
  display: flex;
  flex-direction: column;
  margin: 2%;
}
.container-radio-kine {
  font-size: 20px;
}
.container-radio-entreprise {
  font-size: 20px;
}
#kineCheck,
#companyCheck,
input.form-check-input {
  width: 45px;
  height: 20px;
}

.alertError {
  color: red;
  margin: 2%;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
}
.check-validation {
  font-size: 15px;
  margin: 4%;
}
.form-check {
  display: flex;
  align-items: center;
}
hr {
  border: 1px solid #7a77f2;
  width: 60%;
}
.connecter {
  display: flex;
  justify-content: center;
  color: #7a77f2;
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 5%;
}
.text-alreadyAccount {
  display: flex;
  justify-content: center;
  font-size: 18px;
}

@media (max-width: 680px) {
  .container-form {
    display: flex;
    align-items: center;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 10%;
  }
}

.navbar {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-size: 40px;
  background-color: white;
  color: black;
}

.logo-navbar:hover {
  color: #FFE400;
}

.logo-navbar {
  max-width: 100vw;
  margin: 5px;
}


.container-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  background-color: #ffe400;
  box-shadow: 0 5px 5px rgb(134 134 134);
}

.container-header-button {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.button-header-signin {
  width: 120px;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
}

.button-header-signup {
  border-radius: 8px;
  background-color: #7a77f2;
  color: white;
  cursor: pointer;
}

.title-header {
  margin: 5px;
  font-size: 20px;
  font-family: 'Roboto';
}

.container-onglet {
  display: flex;
}

.onglet {
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 2px;
  font-size: 16px;
  padding: 10px;
}
.onglet:hover {
  color: white;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  text-shadow: 1px 1px 1px #7a77f2;
}

.container-logo-profil {
  display: flex;
  align-items: center;
}

.header-container-avatar-text a {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}
.avatar-text {
  margin-left: 10%;
}

@media screen and (max-width: 768px) {
  .noAvatar {
    display: none;
  }
  .container-onglet {
    display: none;
  }
  .container-header {
    justify-content: space-around;
  }
}

@media screen and (min-width: 768px) {
  .container-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    background-color: #ffe400;
    box-shadow: 0 5px 5px rgb(134 134 134);
  }
  .onglet {
    text-decoration: none;
    border: none;
    background: none;
    cursor: pointer;
    margin-top: 2%;
    font-size: 26px;
    padding: 20px;
  }

  .avatar-profil {
    text-decoration: none;
    font-size: larger;
  }

  .container-header-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-right: 20px;
    align-items: center;
  }
}

.container_image_avatar {
  display: flex;
  align-items: flex-end;
  align-content: stretch;
  justify-content: space-around;
}

.style_avatar {
  border-radius: 50%;
  margin-bottom: 50px;
}
.container_bouton_logout {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin-right: 16%;
  flex-direction: column;
  margin-bottom: 8%;
  margin-top: 2%;
}

.container_bouton {
  display: flex;
  align-items: center;
  margin-bottom: 8%;
  margin-top: 5%;
  flex-direction: column;
}
.container_bouton_save {
  display: flex;
  justify-content: flex-end;
  margin-right: 16%;
  margin-top: 1%;
}

.bouton-creation {
  font-family: 'Roboto';
  height: 70px;
  background: #7a77f2;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  width: 260px;
  cursor: pointer;
  margin-bottom: 5%;
}
.bouton-creation:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.bouton_save {
  font-family: 'Roboto';
  height: 60px;
  background: #7a77f2;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 22px;
  cursor: pointer;
}
.bouton_save:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

input.form-control {
  height: 40px;
  border: 1px solid #7a77f2;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  margin: 1%;
  font-size: 16px;
  width: 100%;
}
.container-form-profil {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.logo-documentation,
.logo-formation,
.logo-service {
  margin: 5%;
  height: 40px;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}

.text-top {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container-avatar-text {
  display: flex;
  width: 100%;
}

.avatar {
  border: 2px solid blue;
  border-radius: 50%;
  height: 240px;
  width: 240px;
}
.avatar-profil {
  height: 50px;
  width: 50px;
  display: flex;
  margin: 5%;
  border: 1px solid blue;
  border-radius: 50%;
}

.container-form-text {
  width: 100%;
}

.container-avatar-profil {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5%;
  margin-top: 5%;
}

.label-form-profil {
  position: relative;
  top: 15px;
  left: 18px;
  background-color: white;
  color: #7a77f2;
  border-radius: 20px;
}
.bouton-logout {
  text-decoration: none;
  border: none;
  cursor: pointer;
  margin-top: 2%;
  font-size: 17px;
  height: 35px;
  border-radius: 10px;
  color: #333;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all 0.35s;
  position: relative;
  z-index: 2;
}
.bouton-logout:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #ff003b;
  transition: all 0.35s;
  border-radius: 10px;
  z-index: -1;
}
.bouton-logout:hover {
  color: #fff;
}
.bouton-logout:hover:after {
  width: 100%;
}
.container_profil {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .container-avatar-text {
    display: flex;
    flex-direction: column;
  }
  .container_profil {
    width: 100%;
    margin-bottom: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container_bouton {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .container_bouton_logout {
    margin-top: 10%;
  }
  .container-form-profil {
    margin-left: 8%;
    margin-right: 8%;
    width: 80%;
  }
  .container_bouton {
    margin-right: 16%;
    margin-left: 16%;
  }
}

.addFormation {
  padding: 10px;
  text-align: center;
  text-shadow: 1px 1px 1px #7a77f2;
}

.formationGlobal {
  text-align: center;
  padding: 10px;
  border: solid 1 px;
  box-shadow: 0 10px 10px rgb(134 134 134);
  margin: 2%;
  margin-bottom: 50px;
}

.bouton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7a77f2;
  height: 40px;
  border-radius: 8px;
  width: 30%;
  font-size: 16px;
}
.bouton:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.container {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}

.select {
  position: relative;
  top: 5px;
  background-color: white;
  color: #7a77f2;
}

.selectField {
  border: 1px solid #7a77f2;
  height: 40px;
  border-radius: 8px;
  width: 85%;
  display: flex;
  font-size: 16px;
  margin-left: 8%;
}
.textAreaLabel {
  position: relative;
  top: 12px;
  background-color: white;
  color: #7a77f2;
}
.textArea {
  border: 1px solid #7a77f2;
  margin-top: 40px;
  border-radius: 5px;
  width: 85%;
  display: flex;
  margin: 1%;
  font-size: 16px;
  margin-left: 8%;
}

.addService {
  padding: 10px;
  text-shadow: 1px 1px 1px #7a77f2;
}

.serviceGlobal {
  text-align: center;
  padding: 10px;
  border: solid 1 px;
  box-shadow: 0 10px 10px rgb(134 134 134);
  margin: 2%;
  margin-bottom: 50px;
}

.bouton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7a77f2;
  height: 40px;
  border-radius: 8px;
  width: 30%;
  font-size: 16px;
}
.bouton:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.container {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}

