@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.container-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  background-color: #ffe400;
  box-shadow: 0 5px 5px rgb(134 134 134);
}

.container-header-button {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.button-header-signin {
  width: 120px;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
}

.button-header-signup {
  border-radius: 8px;
  background-color: #7a77f2;
  color: white;
  cursor: pointer;
}

.title-header {
  margin: 5px;
  font-size: 20px;
  font-family: 'Roboto';
}

.container-onglet {
  display: flex;
}

.onglet {
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 2px;
  font-size: 16px;
  padding: 10px;
}
.onglet:hover {
  color: white;
  transform: scale(1.2);
  text-shadow: 1px 1px 1px #7a77f2;
}

.container-logo-profil {
  display: flex;
  align-items: center;
}

.header-container-avatar-text a {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}
.avatar-text {
  margin-left: 10%;
}

@media screen and (max-width: 768px) {
  .noAvatar {
    display: none;
  }
  .container-onglet {
    display: none;
  }
  .container-header {
    justify-content: space-around;
  }
}

@media screen and (min-width: 768px) {
  .container-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    background-color: #ffe400;
    box-shadow: 0 5px 5px rgb(134 134 134);
  }
  .onglet {
    text-decoration: none;
    border: none;
    background: none;
    cursor: pointer;
    margin-top: 2%;
    font-size: 26px;
    padding: 20px;
  }

  .avatar-profil {
    text-decoration: none;
    font-size: larger;
  }

  .container-header-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-right: 20px;
    align-items: center;
  }
}
