.alldoc {
  text-align: center;
  font-size: 40px;
  padding: 10px;
  text-align: center;
  text-shadow: 1px 1px 1px #7a77f2;
}

.search-engine {
  font-family: 'Hind';
  padding-left: 2%;
}
.documentation-results {
  margin-bottom: 10vw;
}

@media screen and (min-width: 768px) {
  .documentation-results {
    margin-bottom: 5vw;
  }
}
