.search-input {
  height: 35px;
  border-radius: 15px;
  padding: 8px;
  border: 1px solid #7a77f2;
  box-sizing: border-box;
}

@media screen and (min-width: 768px) {
  .search-input {
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: center;
  }
}
