.container_image_avatar {
  display: flex;
  align-items: flex-end;
  align-content: stretch;
  justify-content: space-around;
}

.style_avatar {
  border-radius: 50%;
  margin-bottom: 50px;
}
.container_bouton_logout {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin-right: 16%;
  flex-direction: column;
  margin-bottom: 8%;
  margin-top: 2%;
}

.container_bouton {
  display: flex;
  align-items: center;
  margin-bottom: 8%;
  margin-top: 5%;
  flex-direction: column;
}
.container_bouton_save {
  display: flex;
  justify-content: flex-end;
  margin-right: 16%;
  margin-top: 1%;
}

.bouton-creation {
  font-family: 'Roboto';
  height: 70px;
  background: #7a77f2;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  width: 260px;
  cursor: pointer;
  margin-bottom: 5%;
}
.bouton-creation:hover {
  transform: scale(1.05);
}

.bouton_save {
  font-family: 'Roboto';
  height: 60px;
  background: #7a77f2;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 22px;
  cursor: pointer;
}
.bouton_save:hover {
  transform: scale(1.05);
}

input.form-control {
  height: 40px;
  border: 1px solid #7a77f2;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  margin: 1%;
  font-size: 16px;
  width: 100%;
}
.container-form-profil {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.logo-documentation,
.logo-formation,
.logo-service {
  margin: 5%;
  height: 40px;
  filter: invert(100%);
}

.text-top {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container-avatar-text {
  display: flex;
  width: 100%;
}

.avatar {
  border: 2px solid blue;
  border-radius: 50%;
  height: 240px;
  width: 240px;
}
.avatar-profil {
  height: 50px;
  width: 50px;
  display: flex;
  margin: 5%;
  border: 1px solid blue;
  border-radius: 50%;
}

.container-form-text {
  width: 100%;
}

.container-avatar-profil {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5%;
  margin-top: 5%;
}

.label-form-profil {
  position: relative;
  top: 15px;
  left: 18px;
  background-color: white;
  color: #7a77f2;
  border-radius: 20px;
}
.bouton-logout {
  text-decoration: none;
  border: none;
  cursor: pointer;
  margin-top: 2%;
  font-size: 17px;
  height: 35px;
  border-radius: 10px;
  color: #333;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all 0.35s;
  position: relative;
  z-index: 2;
}
.bouton-logout:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #ff003b;
  transition: all 0.35s;
  border-radius: 10px;
  z-index: -1;
}
.bouton-logout:hover {
  color: #fff;
}
.bouton-logout:hover:after {
  width: 100%;
}
.container_profil {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .container-avatar-text {
    display: flex;
    flex-direction: column;
  }
  .container_profil {
    width: 100%;
    margin-bottom: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container_bouton {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .container_bouton_logout {
    margin-top: 10%;
  }
  .container-form-profil {
    margin-left: 8%;
    margin-right: 8%;
    width: 80%;
  }
  .container_bouton {
    margin-right: 16%;
    margin-left: 16%;
  }
}
